import imageUrl from "utils/ImageUrl"

export const allData = [
    {title: "ISLAND GIRL", category: 'game', img: imageUrl('all/island-girl-game.jpg') },
    {title: "AMIGO SOUND", category: 'blockchain', img: imageUrl('all/amigo-sound-blockchain.jpg') },
    {title: "ASWAAT", category: 'web app', img: imageUrl('all/aswaat-web.jpg') },
    {title: "BASHO SWAP", category: 'blockchain', img: imageUrl('all/basho-swap-blockchain.jpg') },
    {title: "CELESTIAL BLOCK", category: 'blockchain', img: imageUrl('all/celestial-block.jpg') },
    {title: "COLLEGE GOLF CAMP", category: 'web app', img: imageUrl('all/college-golf-camp-web.jpg') },
    {title: "GHALIB", category: 'web app', img: imageUrl('all/ghalib-web.jpg') },
    {title: "HOTIE ENERGY", category: 'blockchain', img: imageUrl('all/hotie-energy-block.jpg') },
    {title: "ORTECH", category: 'web app', img: imageUrl('all/oratech-web.jpg') },
    {title: "PAYREXA", category: 'web app', img: imageUrl('all/payrexa-web.jpg') },
    {title: "TRADEPEDIA", category: 'web app', img: imageUrl('all/tradepedia-web.jpg') },
    {title: "UNIVAULTS", category: 'blockchain', img: imageUrl('all/univaults-block.jpg') },












 
]