import { memo, useState } from "react";
import imageUrl from "../utils/ImageUrl";
import { Bounce } from "react-reveal";
import {
  MobileData,
  frontendData,
  dataBaseData,
  backendData,
  cmsData,
} from "../dummyData";
import { useEffect, useRef } from "react";
function HomeTechnologies() {
  const [firstData, setData] = useState([]);
  const [secondData, setSecondData] = useState([]);
  const [active, setActive] = useState(0);
  const handleData = (data) => {
    setData(data.firstRow);
    setSecondData(data.secondRow);
  };
  const handleActive = (value) => {
    // console.log(value);
    setActive(value);
  };

  const handleClick = (value, data) => {
    handleActive(value);
    handleData(data);
    console.log(value, data);
  };
  useEffect(() => {
    setData(MobileData.firstRow);
    setSecondData(MobileData.secondRow);
  }, []);

  return (
    <section id="home-technologies" className="home-portfolio">
      <div className="main wow fadeDown">
        <div className="banner-container">
          <div className="sec-padding">
            <div className="main-portfolio">
              <div className="row">
                <div className="col-md-3 d-flex flex-column justify-content-evenly">
                  <div className="tabbing-link">
                    <div className="heading">
                      <h1>
                        Technologies
                        <br /> we work <br />
                        with
                      </h1>

                      <span className="underline"></span>
                    </div>
                    <div className="links">
                      <ul>
                        <li
                          className={`${active === 0 ? "active" : ""}`}
                          onClick={(e) =>
                            handleClick(e.target.value, MobileData)
                          }
                          value={0}
                        >
                          Mobile
                        </li>
                        <li
                          value={1}
                          className={`${active === 1 ? "active" : ""}`}
                          onClick={(e) =>
                            handleClick(e.target.value, frontendData)
                          }
                        >
                          Frontend
                        </li>
                        <li
                          className={`${active === 2 ? "active" : ""}`}
                          onClick={(e) =>
                            handleClick(e.target.value, dataBaseData)
                          }
                          value={2}
                        >
                          Database
                        </li>
                        <li
                          className={`${active === 3 ? "active" : ""}`}
                          onClick={(e) =>
                            handleClick(e.target.value, backendData)
                          }
                          value={3}
                        >
                          Backend
                        </li>
                        <li
                          className={`${active === 4 ? "active" : ""}`}
                          onClick={(e) => handleClick(e.target.value, cmsData)}
                          value={4}
                        >
                          CMS
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="col-md-9 d-flex align-items-sm-end">
                  <div className="tech-icons-main">
                    <div className="row">
                      <div className="col-md-12 pb-3">
                        <div className="main-technical-icons">
                          <div className="row">
                            {firstData.map((item, ind) => (
                              <div className="col-3 col-md-3 pb-3" key={item.title}>
                                <Bounce top>
                                  <div className="tech-icons wow fadeInBottomLeft">
                                    <img
                                      src={imageUrl(item.imageUrl)}
                                      alt={item.imageUrl}
                                      className={`${
                                        item.title === "Nodejs" ||
                                        item.title === "PHP"
                                          ? "w-75"
                                          : ""
                                      }`}
                                    />
                                    <h4>{item.title}</h4>
                                  </div>
                                </Bounce>
                              </div>
                            ))}
                            {secondData.map((item, ind) => (
                              <div
                                className={`col-4 col-md-4 ${
                                  ind === 0
                                    ? " d-flex justify-content-end"
                                    : ind === 2
                                    ? " d-flex justify-content-start"
                                    : ""
                                }`}
                                key={item.title}
                              >
                                <Bounce bottom>
                                  <div className="tech-icons">
                                    <img
                                      src={imageUrl(item.imageUrl)}
                                      alt={item.imageUrl}
                                    />
                                    <h4>{item.title}</h4>
                                  </div>
                                </Bounce>
                              </div>
                            ))}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default memo(HomeTechnologies);
