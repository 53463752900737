import React from "react";

import { Footer } from "../../../components/index";
import { CareerBanner } from "../../career/Career";
import { Fade, Slide, Roll, Bounce } from "react-reveal";
import {
  LineBlack,
  Blockchain,
  Seo,
  CustomSoftware,
  Ecommerce,
  Mobileapp,
  GraphicDesign,
} from "../../../assets/images/index";
import { Link, useNavigate } from "react-router-dom";
import { Parallax } from "react-scroll-parallax";
function AllServices() {
  const navigate = useNavigate(); 

  const navigateTo = (path='/') => {
    navigate(path);
  }

  return (
    <>
      <div id="all-services" className="for-parallax-footer">
        {/* =========================banner============ */}
        <CareerBanner title={`Our Services`} />

        {/* =============================section-services========================== */}
        <section id="home-services">
          <div className="main">
            <div className="container">
              <div className="sec-padding">
                <div className="line">
                  <img src={LineBlack} alt="" />
                </div>

                <div className="main-about main-services text-center">
                  <Fade top cascade>
                    <div className="heading">
                      <h6 className="text-grey">Services</h6>
                      <h1 className="text-secondry">Our Awesome Services</h1>
                      <span className="underline"></span>
                    </div>
                  </Fade>
                  <Fade bottom cascade>
                    <div className="para">
                      <p className=" text-secondry">
                        Pluton’s establishment was laid in 2018. Our experience
                        started determined to utilize state-of-the-art
                        innovation to <br />
                        carry transparency and trustworthiness to organization
                        processes.
                      </p>{" "}
                    </div>
                  </Fade>

                  <div className="main-services-card">
                    <div className="row gx-0 gy-2">
                      <div className="col-xlg-4 col-lg-4 col-md-6 col-sm-6 col-xsm-12">
                        <Slide left>
                          <div className="card service-card mx-2"  onClick={()=> navigateTo('/blockchain-dev')}>
                            <div className="service-card-image">
                              <img
                                src={Blockchain}
                                className="black"
                                alt="BlockChain"
                              />
                            </div>
                              {" "}
                              <div className="service-card-heading">
                                <h2>Block chain Development</h2>
                              </div>
                            <div className="service-card-para">
                              <p>
                                Create and Launch your NFTs and ICOs with us,
                                and compete the world by following the latest
                                technologies.
                              </p>
                            </div>
                          </div>
                        </Slide>
                      </div>
                      <div className="col-xlg-4 col-lg-4 col-md-6 col-sm-6 col-xsm-12">
                        <Slide bottom>
                          <div className="card service-card mx-2"  onClick={()=> navigateTo('/mobile-app-dev')}>
                            <div className="service-card-image">
                              <img
                                src={Mobileapp}
                                className="black"
                                alt="Mobileapp.png"
                              />
                            </div>
                              <div className="service-card-heading">
                                <h2>Mobile Application Development</h2>
                              </div>
                            <div className="service-card-para">
                              <p>
                                We are the App Makers! Our expert mobile app
                                developers strive to provide you the best user
                                experience, custom android and iOS apps .
                              </p>
                            </div>
                          </div>
                        </Slide>
                      </div>
                      <div className="col-xlg-4 col-lg-4 col-md-6 col-sm-6 col-xsm-12">
                        <Slide right>
                          <div className="card service-card mx-2" onClick={()=> navigateTo('/web-dev')}>
                            <div className="service-card-image">
                              <img
                                src={Ecommerce}
                                className="black"
                                alt="Ecommerce.png"
                              />
                            </div>
                              <div className="service-card-heading">
                                <h2>Ecommerce Web Development</h2>
                              </div>
                            <div className="service-card-para">
                              <p>
                                Hire our SEO specialists to assist you with
                                on-page SEO, off-page SEO, Technical SEO, and
                                Local SEO services to help you rank better and
                                boost your sales.
                              </p>
                            </div>
                          </div>
                        </Slide>
                      </div>
                      <div className="col-xlg-4 col-lg-4 col-md-6 col-sm-6 col-xsm-12">
                        <Slide left>
                          <div className="card service-card mx-2" onClick={()=> navigateTo('/digital-marketing')}>
                            <div className="service-card-image">
                              <img src={Seo} className="black" alt="SEO.png" />
                            </div>
                              <div className="service-card-heading">
                                <h2>Website Maintenance & Support</h2>
                              </div>
                            <div className="service-card-para">
                              <p>
                                Hire our SEO specialists to assist you with
                                on-page SEO, off-page SEO, Technical SEO, and
                                Local SEO services to help you rank better and
                                boost your sales
                              </p>
                            </div>
                          </div>
                        </Slide>
                      </div>
                      <div className="col-xlg-4 col-lg-4 col-md-6 col-sm-6 col-xsm-12">
                        <Slide bottom>
                          <div className="card service-card mx-2"  onClick={()=> navigateTo('/creative-design')}>
                            <div className="service-card-image">
                              <img
                                src={GraphicDesign}
                                className="black"
                                alt="GraphicDesign.png"
                              />
                            </div>
                              <div className="service-card-heading">
                                <h2>Creative Graphic Designs</h2>
                              </div>
                            <div className="service-card-para">
                              <p>
                                The best digital designers team up together to
                                bring you creative, beautiful and engaging
                                designs. We create excellence!
                              </p>
                            </div>
                          </div>
                        </Slide>
                      </div>
                      <div className="col-xlg-4 col-lg-4 col-md-6 col-sm-6 col-xsm-12" onClick={()=> navigateTo('/software-dev')}>
                        <Slide right>
                          <div className="card service-card mx-2">
                            <div className="service-card-image">
                              <img
                                src={CustomSoftware}
                                className="black"
                                alt="CustomSoftware.png"
                              />
                            </div>
                              <div className="service-card-heading">
                                <h2>Custom Software Development</h2>
                              </div>
                            <div className="service-card-para">
                              <p>
                                Our custom software development services aim to
                                design, engineer, deploy, and support various
                                types of software
                              </p>
                            </div>
                          </div>
                        </Slide>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* <Footer /> */}
      </div>
      <Parallax translateY={[-25, 40]}>
        <Footer />
      </Parallax>
    </>
  );
}

export default AllServices;
