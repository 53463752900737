import React from "react";
import { Footer, Header } from "../../components";
import { CareerBanner } from "../career/Career";
import { Parallax } from "react-scroll-parallax";
import "./privacypolicy.scss";

function Privacypolicy() {

  const para = (
    <h6 className="para">
      PRIVACY & POLICY
    </h6>
  );

  return (
    <>
      <div id="about" className="for-parallax-footer">
        {/* =========================banner============ */}
        <section id="banner">
          <CareerBanner page="about" title={'PRIVACY & POLICY'} />
        </section>
        <section id="about-content">
          <div className="container">
            <div className="sec-padding">
              <div className="row">
                <div className="col-lg-12">
                  <div className="main-about-para">
                    <p className="paraAll">
                      <h2 className="headingHigh"> Privacy Policy </h2> <br />
                      Safeguarding your digital sovereignty is our utmost priority. Welcome to Pluton Privacy Policy, where we protect your personal information with unwavering commitment and transparency.
                    
                    </p>
                    <hr className="lineTwo" />
                    <h1 className="headingHigh-sec">Effective from Year: 2018-19</h1> <br />
                    <p className="paraAll">
                    Pluton adopted this Privacy Policy to reflect our commitment to protecting your privacy. We take the collection of personal information from our Users seriously and are committed to protecting each User’s privacy in accordance with this Privacy Policy.
In this Privacy Policy, we use the term “User” to include any individual who accesses and uses any of the Services.


                    </p>{" "}<hr className="lineTwo" />
                    <h2 className="headingHigh-sec">Where this Privacy Policy applies</h2> <br />
                    <p  className="paraAll">
                    If a particular Service refers or links to a different privacy policy, then that privacy policy — not this Privacy Policy — applies.
Our agreements with certain business customers may contain provisions about the collection, use, storage and disposal of information collected through the Services and offline. If a provision of a customer agreement conflicts or otherwise is inconsistent with a provision of this Privacy Policy, then the provision of the customer agreement will prevail but solely to the extent of the conflict or inconsistency.


                    </p>{" "}<hr className="lineTwo" />
                    <h2 className="headingHigh-sec">How to Contact Us</h2> <br />
                    <p className="paraAll">
                    You may contact Pluton directly if you have any question regarding the Services or our privacy practices.

                    </p>{" "}<hr className="lineTwo" />
                    <h2 className="headingHigh-sec">Information we Collect</h2> <br />
                    <p className="paraAll">
                    We provide various informational, educational, and entertainment features as part of the Services. To operate these features, we collect information from our Users. The types of information that Pluton collects depends on the purpose for which each User chooses to use the Services.
We use the information we collect from Users to provide more meaningful editorial and advertising content and to offer relevant products and services, as well as to maintain, protect and improve Users’ overall experience.
When a User subscribes to our services we may collect the name, email address and postal service address of the subscriber and, if a gift subscription, of the purchaser.
When a User submits a request for support, Pluton may collect the following types of information: name, telephone number, email address and other information that the User chooses to give us to respond to the support request.
In addition to the information you provide us directly, we receive information about you from service providers that help us operate and improve the Services including for data hosting and maintenance, analytics, marketing and security operations. All of our service providers must adhere to confidentiality obligations that are consistent with this Privacy Policy and the agreements we enter into with them.
When you connect with us through a social media platform, we may, depending on your privacy settings, receive some information from your social media account.
When you use the Services, we use collect information about which features you’ve used, how you’ve used them and the devices you use to access the Services. Depending on the settings of a User’s computer or mobile device (“Device“), Pluton also automatically collects: Internet Protocol (IP) address; MAC address; Device make, model and operating system version; mobile network information; internet service provider; browser type and language; country and time zone in which the Device is located; and metadata stored on the Device. This information is collected through cookies and other tracking tools. Pluton may supplement the information you provide to us with information from other sources. All of this information is treated as personal information when it directly or indirectly identifies an individual User.


                    </p>
                    
                  </div>
                </div>
              </div>
                    <hr className="lineTwo" />
              <div className="row my-3">
                
                  <div className="heading">
                    <h2 className="headingHigh-sec">How we Use Information</h2>
                    {/* <hr className="line" /> */}
                  
                  <div className="para">
                    <p className="paraAll">
                    
We use information to operate the Services, including:
<h5 className="strong-para">

To administer your account and provide the Services to you
  </h5><br/>
Create and manage your account
Provide you with customer support and respond to your requests
Communicate with you about the Services
<h5 className="strong-para">


To inform you about offers and events
  </h5><br/>
Administer sweepstakes, contests, discounts or other offers with the support of affiliated or non-affiliated sponsors and/or promotional partners. For each contest or sweepstakes, we will provide notice of the applicable rules and if necessary, post any supplemental privacy provisions. To the extent there is a conflict between those privacy provisions and this Privacy Policy, the privacy provisions for the specific contest or sweepstakes will govern but solely to the extent of the conflict.
Develop, display and track content and advertising tailored to your interests on the Services and other websites
Communicate with you by email, phone, social media or mobile device about products or services that we think may interest you
The information collected in conjunction with events may also be used for the marketing of additional services and events by us and/or our promotional partners and sponsors beyond the contest, sweepstakes and/or special event. Depending on the event, Users either grant their approval on how their information is used before it is collected (‘opt-in’), or choose to deny use of their information for additional purposes (‘opt-out’).
<h5 className="strong-para">



To improve the Services and develop new ones
  </h5><br/>
Administer focus groups and surveys
Conduct research and analysis of Users’ behavior to improve the Services
Develop new features and services.
<h5 className="strong-para">



To prevent, detect and fight fraud or other illegal or unauthorized activities
  </h5><br/>
Address ongoing or alleged fraud or other misbehavior on or though the Services
Analyze data to better understand and design countermeasures against fraud
Retain data related to fraudulent activities to prevent against recurrences
<h5 className="strong-para">

To ensure legal compliance
  </h5><br/>
Comply with legal requirements
Assist law enforcement
Enforce or exercise our rights, for example our Terms of ServiceF
<h5 className="strong-para">

To process your information as described in this Privacy Policy, we rely on the following legal bases:
  </h5><br/>
Legitimate interests: We may use your information where we have legitimate interests to do so. For example, we analyze our Users’ behavior to improve the Services, to prevent and detect fraud and misuse.
Consent: From time to time, we may ask for your consent to use your information. You may withdraw your consent at any time by contacting us using the contact information provided above.

                   
                    </p>
                  </div>
                </div>
              </div>
                    <hr className="lineTwo" />
              <div className="row my-2">
                <div className="col-lg-12">
                  <div className="heading">
                    <h2 className="headingHigh-sec" >Use and Collection of Information by Operational Providers</h2>
                 
                  </div>
                  <div className="para">
                    <p className="paraAll">
                    For the convenience of our Users, we may provide the opportunity to purchase certain goods, merchandise and services through the Services. Companies other than Pluton may handle some of these transactions. We call the companies that conduct our e-commerce operations, order fulfillment and/or contract services our “operational providers.” If you choose to use these Services, our operational providers will request information to fulfill your order or request. The voluntary submission of your personal information to these operational providers, including your order or request, is governed by the specific operational provider’s privacy and other terms. To facilitate an order or request, we may share information with the operational provider. The operational provider also may share your information with us. We will store this information in our User database. In most instances, we request that our operational providers adhere to the provisions in this Privacy Policy and only share Users’ personal information with us, unless necessary to complete a User’s request or order. We also request that these providers only use the personal information for the purpose of conducting the sale or fulfilling the requested service or order.
                    </p>
                  </div>
                </div>
              </div>
              <hr className="lineTwo" />
              <div className="row my-2">
                <div className="col-lg-12">
                  <div className="heading">
                    <h2 h2 className="headingHigh-sec">How we share Information</h2>
                   
                  </div>
                  <div className="para">
                    <h4 className="strong-para">With our operational providers and partners</h4> <br />
                    <p className="paraAll">
We use third-party operational providers to help us operate and improve the Services. These third parties assist us with data hosting and maintenance, analytics, customer care, marketing, payment processing and security operations. All of our service providers must adhere to confidentiality obligations that are consistent with this Privacy Policy.
 <h5 className="strong-para">
   With our affiliates
  </h5><br/>
We share your information with our affiliates to help us with technical processing operations, such as data hosting and maintenance, customer care, marketing and targeted advertising, better understanding how the Services are used and Users’ behavior to improve the Services, securing our data and systems, fighting against spam, abuse, fraud and intellectual property infringement.

<h5 className="strong-para">
For corporate transactions
  </h5><br/>
We may transfer your information if we are involved, whether in whole or in part, in a merger, sale, acquisition, divestiture, restructuring, reorganization, dissolution, bankruptcy or other change of ownership or control.
<h5 className="strong-para">

When required by law
  </h5><br/>
Applicable law may require us and our service providers to disclose your information if: (i) reasonably necessary to comply with a legal process, such as a court order, subpoena or search warrant, government investigation or other legal requirements; or (ii) necessary for the prevention or detection of crime (subject in each case to applicable law).
<h5 className="strong-para">

To enforce legal rights
  </h5><br/>
We may also share information: (i) if disclosure would mitigate our liability in an actual or threatened lawsuit; (ii) as necessary to protect our legal rights and legal rights of our users, business partners or other interested parties; (iii) to enforce our agreements with you; and (iv) to investigate, prevent, or take other action regarding illegal activity, suspected fraud or other wrongdoing.
<h5 className="strong-para">

With your consent or at your request
  </h5><br/>
We may ask for your consent to share your information with third parties. When we do, we will make clear why we want to share the information.
We may collect and provide aggregate information about our Users (such as how many persons visited a particular page or activity or the likes and dislikes of our Users) to our advertisers, sponsors, promotional partners and affiliates but this aggregated information does not include personal information about any individual User.

                    </p>
                  </div>
                </div>
              </div>
                <hr className="lineTwo" />

                <div className="row my-2">
                <div className="col-lg-12">
                  <div className="heading">
                    <h2 className="headingHigh-sec" >Our Use of Cookies and Tracking Tools</h2>
                 
                  </div>
                  <div className="para">
                    <p className="paraAll">
                    Our Websites use cookies and other tracking tools.
A cookie is a small data file that contains a unique identification number that a website places on your hard drive when you visit a site. The cookie stores information about your computer, the type of browser you use, and how many times your computer visits a certain site. Cookies enable us to track how often Users are visiting our Websites and the specific pages visited, the number of entries in specific events, the estimated audience size for sponsors and advertisers, and other User preferences. You can refuse to use cookies by turning them off in your browser. You do not need to have cookies turned on to use most of the Websites. However, you may find that some areas on the Websites will be slower, or may not function at all, and you may not be able to participate in certain activities such as contests or sweepstakes if the cookies are disabled.

                    </p>
                  </div>
                </div>
              </div>

              <hr className="lineTwo" />

<div className="row my-2">
<div className="col-lg-12">
  <div className="heading">
    <h2 className="headingHigh-sec" >Use and Collection of Information by Advertisers and Sponsors; Third Party Cookies and Tracking Tools</h2>
 
  </div>
  <div className="para">
    <p className="paraAll">
    We use third-party advertising companies to serve ads on the Websites. These advertising companies may collect and use information (not including your name, address, e-mail address or telephone number) about your visits to the Websites and other websites not owned and operated by us, and may also combine this information with other data about your purchases and interests from other online and offline sources, in order to provide advertisements about goods and services that may interest you (as inferred from your online activity).
We also share usage information about Users of the Websites with these companies for the purpose of managing and targeting advertisements and for market research analysis on the Websites and other websites. For these purposes, we and our third party advertising companies may note some of the pages you visit on the Websites through the use of GIFs. In the course of serving advertisements to the Websites, our third party advertisers may place or recognize a unique ‘cookie’ on your computer. The Websites may post banner ads and other forms of advertisements, and/or links to the websites of affiliate entities or of advertisers or sponsors who are companies that are not owned or operated by us, or our affiliates or subsidiaries. These entities may independently solicit and collect personal information, or send their own cookies and/or clear GIFs to our Users. The data collected is used in order to provide advertisements about goods and services that may be of interest to Users of the Websites and other interactive media and may be used to keep track of user response to each advertisement. We also work with third-party data analytics and online targeting companies. Some of these companies may use non-personal information (not including your name, address, email address or telephone number) about your visits to the Websites and other websites in order to provide data and targeting recommendations based on which we may provide advertisements about goods and services of interest to you.
Many of the third-party companies we work with are members of the Network Advertising Initiative (NAI), a cooperative on online marketing and analytics companies committed to building consumer awareness and establishing responsible business and data management practices. The NAI has developed a tool that allows consumers to opt out of targeted advertising delivered by NAI members’ ad networks.
Following are the names and websites of some of the other companies who may set cookies on the Websites to serve or present the ads that appear on the Websites and to conduct research about the advertisements. You may learn about opting out of their targeted advertising programs by going to their respective websites.


    </p>
  </div>
</div>
</div>
<hr className="lineTwo" />


<div className="row my-2">
<div className="col-lg-12">
  <div className="heading">
    <h2 className="headingHigh-sec" >How we Protect and Retain Information</h2>
 
  </div>
  <div className="para">
    <p className="paraAll">
    We use physical, technical and organizational measures designed to protect your information against unauthorized access, theft and loss. We restrict access to your personal information to those employees who need to know that information to service your account or perform their job functions.
Although we takes precautions intended to help protect information that we process, no system or electronic data transmission is completely secure. Any transmission of your personal data is at your own risk and we expect that you will use appropriate security measures to protect your personal information.
You are responsible for maintaining the security of your account and the information in your account. We may suspend your use of all or part of the Services without notice if we suspect or detect any breach of security. You understand and agree that we may deliver electronic notifications about breaches of security to the email address on record on your account.
If you believe that your account or information is no longer secure, please notify us immediately at privacy [at] Pluton.
We will retain your personal information for the period necessary to fulfill the purposes outlined in this Privacy Policy unless a longer retention period is required or permitted by law.
The criteria used to determine our retention periods include:
The length of time we have an ongoing relationship with you and provide services to you (for example, for as long as you have an account with us or keep using the Websites);
Whether there is a legal obligation to which we are subject (for example, certain laws require us to keep records of your transactions for a certain period of time before we can delete them)
Whether retention is advisable; and considering our legal position (such as, for statutes of limitations, litigation or regulatory investigations).



    </p>
  </div>
</div>
</div>

<hr className="lineTwo" />


<div className="row my-2">
<div className="col-lg-12">
  <div className="heading">
    <h2 className="headingHigh-sec" >Cross Border Data Transfers</h2>
 
  </div>
  <div className="para">
    <p className="paraAll">
    Sharing of information sometimes involves cross-border data transfers to or from the United States of America and other jurisdictions. For example, where the Services are available to Users in the European Economic Area (“EEA”), personal information is transferred to the United States. We use the EU-US and Swiss-US Privacy Shield and standard contractual clauses approved by the European Commission to validate transfers of EEA residents’ personal information from the EEA to other countries. Standard contractual clauses are commitments between companies transferring personal information of EEA residents to protect the privacy and security of the transferred personal information.

    </p>
  </div>
</div>
</div>

<hr className="lineTwo" />


<div className="row my-2">
<div className="col-lg-12">
  <div className="heading">
    <h2 className="headingHigh-sec" >Your Choice about your Information</h2>
 
  </div>
  <div className="para">
    <p className="paraAll">
    If you would like to review, correct, update, suppress, restrict or delete personal information (including Personal Data as defined in the PRIVACY SHIELD section below) that you have previously provided to us, or if you would like to receive an electronic copy of your personal information for purposes of transmitting it to another company (if this right to data portability is provided to you by law).
In your request, please make clear what personal information you would like to have changed, whether you would like to have your personal information suppressed from our database, or other limitations you would like to put on our use of your personal information. For your protection, we only fulfill requests for the personal information associated with the particular email address that you use to send us your request, and we may need to verify your identity before fulfilling your request. We will try to comply with your request as soon as reasonably practicable.
Please note that we often need to retain certain data for recordkeeping purposes and/or to complete any transaction that you began prior to requesting a change or deletion (e.g., when you make a purchase or enter a promotion, you may not be able to change or delete the personal information provided until after the completion of such purchase or promotion). Our databases and other records may have residual data which will not be removed. In addition, we also may not allow you to review certain data for legal, security or other reasons. If at any time you believe that the Services have not adhered to this Privacy Policy, please let us know. We will use good faith efforts to determine and correct the problem.


    </p>
  </div>
</div>
</div>


<hr className="lineTwo" />


<div className="row my-2">
<div className="col-lg-12">
  <div className="heading">
    <h2 className="headingHigh-sec" >Links to Other Websites and Sevices</h2>
 
  </div>
  <div className="para">
    <p className="paraAll">
    The Services may include links to third-party websites and services that are not operated by us. When you click these links, you will be directed away from the Services. A link to a third-party website or service does not mean that we endorse it or the quality or accuracy of information presented on it. If you decide to visit a third-party website or service, you are subject to its privacy practices and policies, not ours. This Privacy Policy does not apply to any personal information that you provide to these other websites and services.

    </p>
  </div>
</div>
</div>
<hr className="lineTwo" />
         

<div className="row my-2">
<div className="col-lg-12">
  <div className="heading">
    <h2 className="headingHigh-sec" >Privacy Shield Principles</h2>
 
  </div>
  <div className="para">
    <p className="paraAll">
    Pluton commits to processing Personal Data in accordance with the Privacy Shield Principles as follows:
    <h5 className="strong-para">

Notice
  </h5><br/>
Prior to collecting Personal Data Pluton notifies EU Users about the categories of Personal Data that Pluton collects and the purposes for collection and use of their Personal Data. Pluton will only process Personal Data in ways that are compatible with the purpose for which Pluton collected it or for purposes later authorized.
<h5 className="strong-para">


Choice
  </h5><br/>
If Pluton collects Sensitive Personal Data, we will obtain explicit opt-in consent whenever Privacy Shield requires. Pluton will obtain opt-in consent before Personal Data is disclosed to third parties other than those described in this Privacy Policy, before Personal Data is used for a different purpose than that purpose for which it was collected or later authorized, and whenever Privacy Shield requires.
<h5 className="strong-para">


Accountability for Onward Transfer
  </h5><br/>
If Pluton transfers Personal Data to a third party, Pluton takes reasonable and appropriate steps to ensure that each third party transferee processes Personal Data transferred in a manner consistent with Pluton obligations under the Privacy Shield Principles. Pluton will ensure that each transfer is consistent with any notice provided to EU Users and any consent they have given.
<h5 className="strong-para">

Data Integrity and Purpose Limitation
  </h5><br/>
Pluton takes reasonable steps to ensure that such Personal Data is reliable for its intended use, accurate, complete and current. Pluton adheres to the Privacy Shield Principles for as long as it retains Personal Data in identifiable form. Pluton takes reasonable and appropriate measures to comply with the requirement under the Privacy Shield to retain Personal Data in identifiable form only for as long as it serves a purpose of processing.

    </p>
  </div>
</div>
</div>
<hr className="lineTwo" />

<div className="row my-2">
<div className="col-lg-12">
  <div className="heading">
    <h2 className="headingHigh-sec" >Changes in the Privacy Policy</h2>
 
  </div>
  <div className="para">
    <p className="paraAll">
    The Effective Date at the top of this page indicates when this Privacy Policy was last revised. Unless applicable law prevents or a change is needed to protect the privacy or security of our uses, we will notify you before any material change takes effect so that you have time to review the changes before they are effective.
    </p>
  </div>
</div>
</div>


            </div>
          </div>
        </section>
      </div>
      {/* <Footer /> */}
      <Parallax translateY={[-25, 40]}>
        <Footer />
      </Parallax>
    </>
  );
}

export default Privacypolicy;
