// import React, { useEffect } from "react";
import "./home.scss";
import { ContactUs } from "pages";
import { Link, useNavigate } from "react-router-dom";
import Slider from "react-slick";
import { Header, Footer, HomeTechnologies } from "../../components/index";
import LinkedInTracking from '../../components/LinkForId/Link'
import { Fade, Slide, Roll, Bounce, Flip } from "react-reveal";
import Swing from "react-reveal/Swing";
import { Parallax } from "react-scroll-parallax";
import {
  Apple,
  // BlockchainIcon,
  bannerImg2,
  bannerGroup,
  bannerImg,
  bannerImgBg,
  Android,
  Flutter,
  Ionic,
  Reactnative,
  Kotlin,
  Swift,
  lineImage,
  LineBlack,
  Blockchain,
  Seo,
  CustomSoftware,
  Ecommerce,
  Mobileapp,
  GraphicDesign,
  OluaryLogo,
  Oluary,
  BarterExchange,
  BarterExchangeLogo,
  IslandGirl,
  IslandGirlLogo,
  BannerBgLayer,
  lap1,
  lap2,
  lap3,
  lap4,
  pluton,
  character,
} from "../../assets/images/index";

function Home() {
  const navigate = useNavigate(); 

  var BannerSettings = {
    dots: true,
    autoplay: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    speed: 1000,
    infinite: true,
    pauseOnHover:false,
    fade: true,
    vertical: true,
    verticalSwiping: true,
    centerPadding: "60px",
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
          vertical: true,
          verticalSwiping: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
          vertical: false,
          verticalSwiping: false,
          centerPadding: "60px",
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          autoplay: false,
          vertical: false,
          verticalSwiping: false,
          centerPadding: "60px",
        },
      },
    ],
  };

  const navigateTo = (path='/') => {
    navigate(path);
  }

  return (
    <>
      <div id="home" className="for-parallax-footer">
        {/* ==============================section-banner========================== */}
        <section id="banner">
          {/*  */}
          {/* <img
            src={BannerBgLayer}
            alt=""
            style={{
              zIndex: "-2",
              position: "absolute",
              width: "100%",
              height: "100vh",
            }}
          /> */}
          <Header />
          <div className="container">
            <div className="main-banner">
              <div className="banner-container ">
                <Slider {...BannerSettings}>
                  <div className="main main-1">
                    <div className="row">
                      <div className="col-md-5 col-xl-6">
                        <div className="main-banner-content">
                          <div className="content">
                            <Slide left>
                              <h2>One of the Top</h2>
                              <h1>BLOCKCHAIN</h1>
                              <h2>Company at your disposal</h2>
                            </Slide>
                          </div>
                        </div>
                        <div className="banner-btn">
                          <Fade left>
                            <Link to="/contact">
                              <button className="button">Get Quote</button>
                            </Link>
                          </Fade>
                        </div>
                      </div>

                      <div className="col-md-7 col-xl-6">
                        <div className="banner-column">
                          <div className="main-banner-image">
                            <div className={`image text-center wow`}>
                              {/* <img src={bannerImgBg} alt="" />
                            <img src={bannerImg} alt="" /> */}
                              <img src={bannerGroup} alt="" />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>{" "}
                  </div>
                  <div className="main main-2">
                    <div className="row">
                      <div className="col-md-5 col-xl-6">
                        <div className="main-banner-content main-banner-content-2 ">
                          <div className="content">
                            <Slide left>
                              <h2>Efficient design Mobile</h2>
                              <h1>Applications runs</h1>
                              <h2>your functions swiftly.</h2>
                            </Slide>
                          </div>
                        </div>
                        <div className="banner-btn">
                          <Fade left>
                          <Link to="/contact">
                            <button className=" button">Get Quote</button>
                            </Link>
                          </Fade>
                        </div>
                      </div>
                      <div className="col-md-7 col-xl-6">
                        <div className="banner-column">
                          <div className="main-banner-image">
                            <div className={`image text-center wow `}>
                              <img src={bannerImg2} alt="banner-2" />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>{" "}
                  </div>
                </Slider>
              </div>
            </div>
          </div>
        </section>
        {/* =============================section-about========================== */}

        <section id="home-about">
          <div className="main ">
            <div className="container">
              <div className="sec-padding">
                <div className="line">
                  <img src={lineImage} alt="line.png" />
                </div>

                <div className="main-about text-center ">
                  <Fade bottom cascade>
                    <div className="heading">
                      <h6>About</h6>
                      <h1>Providing Assistance with Excellence!</h1>
                      <span className="underline"></span>
                    </div>
                  </Fade>
                  <Fade bottom cascade>
                    <div className="para">
                      <p>
                        Pluton was established in 2018 with a mission to bring transparency and trustworthiness to organizational processes through state-of-the-art innovation.
                      </p>{" "}
                      <p>
                        {" "}
                        As Pluton's team expanded with additional innovative enthusiasts, 
                        we embraced the philosophy of forming partnerships rather than just client relationships. 
                        This approach allows us to connect our vision with yours and work towards a shared goal.
                      </p>{" "}
                      <p>
                        With expertise, creativity, and innovation, 
                        we not only understand your vision but also help transform it into reality by integrating technology into corporate processes and enhancing and scaling technical products. 
                        Our goal is to build trust and dependence.
                      </p>{" "}
                      <p>
                        Pluton stands out as one of the most incredible technological service providers globally. 
                        We believe in utilizing the best technological solutions to address your concerns. 
                        You can entrust your dreams to Pluton Limited, as our skilled and experienced team strives for perfection. 
                        We have partnered with the best to implement cutting-edge technologies for our clients.
                      </p>
                      <p>
                        Over the years, we have catered to numerous organizations in web and application development, 
                        games development, NFTs, Blockchain and ICOs. Whether it's CMS web design, software development, 
                        custom PHP development, WordPress development, e-commerce web development, SEO, SMM, Android app development, 
                        iOS app development, 
                        or the creation of coins and NFTs using Ethereum or Solana, we have you covered!
                      </p>
                    </div>
                  </Fade>
                  <Fade>
                    <div className="about-btn">
                      <Link to={"/about"}>
                        <button className="home-about-btn">Learn More</button>
                      </Link>
                    </div>
                  </Fade>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* =============================section-services========================== */}
        <section id="home-services">
          <div className="main">
            <div className="container">
              <div className="sec-padding">
                <div className="line">
                  <img src={LineBlack} alt="" />
                </div>

                <div className="main-about main-services text-center">
                  <Fade top cascade>
                    <div className="heading">
                      <h6 className="text-grey">Services</h6>
                      <h1 className="text-secondry">Our Awesome Services</h1>
                      <span className="underline"></span>
                    </div>
                  </Fade>
                  <Fade bottom cascade>
                    <div className="para">
                      <p className=" text-secondry">
                        Pluton’s establishment was laid in 2018. Our experience
                        started determined to utilize state-of-the-art
                        innovation to <br />
                        carry transparency and trustworthiness to organization
                        processes.
                      </p>{" "}
                    </div>
                  </Fade>

                  <div className="main-services-card">
                    <div className="row gx-0 gy-2">
                      <div className="col-xlg-4 col-lg-4 col-md-6 col-sm-6 col-xsm-12">
                        <div className="main-card h-100">
                          <Slide left>
                            <div className="card service-card mx-2" onClick={()=> navigateTo('/blockchain-dev')} >
                              <div className="service-card-image">
                                <img
                                  src={Blockchain}
                                  className="black"
                                  alt="BlockChain"
                                />
                              </div>
                                {" "}
                                <div className="service-card-heading">
                                  <h2>Block chain Development</h2>
                                </div>
                              <div className="service-card-para">
                                <p>
                                  Create and Launch your NFTs and ICOs with us,
                                  and compete the world by following the latest
                                  technologies.
                                </p>
                              </div>
                            </div>
                            {/* <div class="progress">
                              <div
                                class="progress-bar bg-warning"
                                role="progressbar"
                                style={{ width: "75%" }}
                                aria-valuenow="75"
                                aria-valuemin="0"
                                aria-valuemax="100"
                              ></div>
                            </div> */}
                          </Slide>
                        </div>
                      </div>
                      <div className="col-xlg-4 col-lg-4 col-md-6 col-sm-6 col-xsm-12" onClick={()=> navigateTo('/mobile-app-dev')}>
                        <Slide bottom>
                          <div className="card service-card mx-2">
                            <div className="service-card-image">
                              <img
                                src={Mobileapp}
                                className="black"
                                alt="Mobileapp.png"
                              />
                            </div>
                              <div className="service-card-heading">
                                <h2>Mobile Application Development</h2>
                              </div>
                            <div className="service-card-para">
                              <p>
                                We are the App Makers! Our expert mobile app
                                developers strive to provide you the best user
                                experience, custom android and iOS apps .
                              </p>
                            </div>
                          </div>
                        </Slide>
                      </div>
                      <div className="col-xlg-4 col-lg-4 col-md-6 col-sm-6 col-xsm-12" onClick={()=> navigateTo('/web-dev')}>
                        <Slide right>
                          <div className="card service-card mx-2">
                            <div className="service-card-image">
                              <img
                                src={Ecommerce}
                                className="black"
                                alt="Ecommerce.png"
                              />
                            </div>
                              <div className="service-card-heading">
                                <h2>Ecommerce Web Development</h2>
                              </div>
                            <div className="service-card-para">
                              <p>
                                Hire our SEO specialists to assist you with
                                on-page SEO, off-page SEO, Technical SEO, and
                                Local SEO services to help you rank better and
                                boost your sales.
                              </p>
                            </div>
                          </div>
                        </Slide>
                      </div>
                      <div className="col-xlg-4 col-lg-4 col-md-6 col-sm-6 col-xsm-12" onClick={()=> navigateTo('/digital-marketing')}>
                        <Slide left>
                          <div className="card service-card mx-2">
                            <div className="service-card-image">
                              <img src={Seo} className="black" alt="SEO.png" />
                            </div>
                              <div className="service-card-heading">
                                <h2>Website Maintenance & Support</h2>
                              </div>
                            <div className="service-card-para">
                              <p>
                                Hire our SEO specialists to assist you with
                                on-page SEO, off-page SEO, Technical SEO, and
                                Local SEO services to help you rank better and
                                boost your sales
                              </p>
                            </div>
                          </div>
                        </Slide>
                      </div>
                      <div className="col-xlg-4 col-lg-4 col-md-6 col-sm-6 col-xsm-12" onClick={()=> navigateTo('/creative-design')}>
                        <Slide bottom>
                          <div className="card service-card mx-2">
                            <div className="service-card-image">
                              <img
                                src={GraphicDesign}
                                className="black"
                                alt="GraphicDesign.png"
                              />
                            </div>
                              <div className="service-card-heading">
                                <h2>Creative Graphic Designs</h2>
                              </div>
                            <div className="service-card-para">
                              <p>
                                The best digital designers team up together to
                                bring you creative, beautiful and engaging
                                designs. We create excellence!
                              </p>
                            </div>
                          </div>
                        </Slide>
                      </div>
                      <div className="col-xlg-4 col-lg-4 col-md-6 col-sm-6 col-xsm-12"  onClick={()=> navigateTo('/software-dev')}>
                        <Slide right>
                          <div className="card service-card mx-2">
                            <div className="service-card-image">
                              <img
                                src={CustomSoftware}
                                className="black"
                                alt="CustomSoftware.png"
                              />
                            </div>
                              <div className="service-card-heading">
                                <h2>Custom Software Development</h2>
                              </div>
                            <div className="service-card-para">
                              <p>
                                Our custom software development services aim to
                                design, engineer, deploy, and support various
                                types of software
                              </p>
                            </div>
                          </div>
                        </Slide>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* ============================section-caseStudy========================== */}
        <section id="case-study">
          <div className="main">
            <div className="container">
              <div className="main-row">
                <div className="row mt-5">
                  <div className="col-lg-6 col-md-12 col-sm-12 text-center text-lg-start">
                    <Fade bottom>
                      <div className="img d-flex justify-content-center">
                        <img src={Oluary} alt="oluary.png" className="w-100" />
                      </div>
                    </Fade>
                  </div>
                  <div className="col-lg-6 col-md-12 col-sm-12 text-center text-lg-start">
                    <Fade bottom cascade>
                      <div className="content">
                        <div className="logo mt-5">
                          <img
                            src={OluaryLogo}
                            alt="oluary-logo.png"
                            className="w-15"
                          />
                          <div className="heading mt-3">
                            <h1>Oluary</h1>
                          </div>
                        </div>
                        <div className="sub-heading mt-5">
                          <h2>
                            MicroPets side- <br /> scrolling nft
                          </h2>
                        </div>
                        <div className="para">
                          <p className="text-secondry">
                            MicroPets Runner is a classic side-scrolling runner
                            game that allows users to proceed with their game
                            character (a MicroPets NFT product) picking up coins
                            (to earn rewards) on their way past monsters and{" "}
                            <br /> obstacles
                          </p>
                        </div>
                        <div className="button mt-4">
                          <Link
                            to="/case-study-1"
                            className="custome-btn-secondry"
                          >
                            View Case Study
                          </Link>
                        </div>
                      </div>
                    </Fade>
                  </div>
                </div>
                <div className="row mt-5 flex-column-reverse flex-lg-row">
                  <div className="col-lg-6 col-md-12 col-sm-12 text-center text-lg-start">
                    <Fade bottom cascade>
                      <div className="content content-2">
                        <div className="logo mt-5">
                          <img
                            src={IslandGirlLogo}
                            alt="IslandGirlLogo.png"
                            className="w-15"
                          />
                          <div className="heading mt-3">
                            <h2>Island</h2>
                            <h1>GIRL</h1>
                          </div>
                          <div className="sub-heading mt-1">
                            <h2>
                              MicroPets side- <br /> scrolling nft
                            </h2>
                          </div>
                        </div>

                        <div className="para">
                          <p className="text-secondry">
                            MicroPets Runner is a classic side-scrolling runner
                            game that allows users to proceed with their game
                            character (a MicroPets NFT product) picking up coins
                            (to earn rewards) on their way past monsters and{" "}
                            <br /> obstacles
                          </p>
                        </div>
                        <div className="button mt-4">
                          <Link
                            to="/case-study-2"
                            className="custome-btn-secondry"
                          >
                            View Case Study
                          </Link>
                        </div>
                      </div>
                    </Fade>
                  </div>
                  <div className="col-lg-6 col-md-12 col-sm-12 text-center text-lg-start">
                    <Fade bottom>
                      <div className="img d-flex justify-content-center">
                        <img
                          src={IslandGirl}
                          alt="Island-girl.png"
                          className="w-100"
                        />
                      </div>
                    </Fade>
                  </div>
                </div>
                <div className="row mt-5 mb-5">
                  <div className="col-lg-6 col-md-12 col-sm-12 text-center text-lg-start">
                    <Fade bottom>
                      <div className="img d-flex justify-content-center">
                        <img
                          src={BarterExchange}
                          alt="BarterExchange.png"
                          className="w-100"
                        />
                      </div>
                    </Fade>
                  </div>
                  <div className="col-lg-6 col-md-12 col-sm-12 text-center text-lg-start">
                    <Fade bottom cascade>
                      <div className="content">
                        <div className="logo mt-5">
                          <img
                            src={BarterExchangeLogo}
                            alt="barter-exchange-logo.png"
                            className="w-15"
                          />
                          <div className="heading mt-3">
                            <h1 className="light-grey">BARTER</h1>

                            <h2 className="dark-grey">EXCHANGE</h2>
                          </div>
                        </div>
                        <div className="sub-heading mt-3">
                          <h2>
                            MicroPets side- <br /> scrolling nft
                          </h2>
                        </div>
                        <div className="para">
                          <p className="text-secondry">
                            MicroPets Runner is a classic side-scrolling runner
                            game that allows users to proceed with their game
                            character (a MicroPets NFT product) picking up coins
                            (to earn rewards) on their way past monsters and{" "}
                            <br /> obstacles
                          </p>
                        </div>
                        <div className="button mt-4">
                          <Link
                            to="/case-study-3"
                            className="custome-btn-secondry"
                          >
                            View Case Study
                          </Link>
                        </div>
                      </div>
                    </Fade>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* =============================section-portfolio========================== */}
        {/* <section id="home-technologies" className="home-portfolio">
          <div className="main wow fadeDown">
            <div className="banner-container">
              <div className="sec-padding">
                <div className="main-portfolio">
                  <div className="row">
                    <div className="col-md-3 d-flex flex-column justify-content-evenly">
                      <div className="tabbing-link">
                        <div className="heading">
                          <h1>
                            Technologies
                            <br /> we work <br />
                            with
                          </h1>

                          <span className="underline"></span>
                        </div>
                        <div className="links">
                          <ul>
                            <li className="active">
                              <a href="#mobile">Mobile</a>
                            </li>
                            <li>
                              <a href="#front-end">Front end</a>
                            </li>
                            <li>
                              <a href="#data-base">Data base</a>
                            </li>
                            <li>
                              <a href="#backend">Backend</a>
                            </li>
                            <li>
                              <a href="#cms">CMS</a>
                            </li>
                            <li>
                              <a href="#infra-develop">Infra & develop</a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-9 d-flex align-items-sm-end">
                      <div className="tech-icons-main">
                        <div className="row">
                          <div className="col-md-12 pb-3">
                            <div className="main-technical-icons">
                              <Bounce top>
                                <div className="tech-icons wow fadeInBottomLeft">
                                  <img src={Apple} alt="" />
                                  <h4>IOS</h4>
                                </div>
                              </Bounce>
                              <Bounce top>
                                <div className="tech-icons">
                                  <img src={Android} alt="" />
                                  <h4>Android</h4>
                                </div>
                              </Bounce>
                              <Bounce top>
                                <div className="tech-icons">
                                  <img src={Flutter} alt="" />
                                  <h4>Flutter</h4>
                                </div>
                              </Bounce>

                              <Bounce top>
                                <div className="tech-icons">
                                  <img src={Reactnative} alt="" />
                                  <h4>React Native</h4>
                                </div>
                              </Bounce>
                            </div>
                          </div>

                          <div className="col-md-12">
                            <div className="main-technical-icons justify-content-evenly">
                              <Bounce bottom>
                                <div className="tech-icons">
                                  <img src={Ionic} alt="" />
                                  <h4>Ionic</h4>
                                </div>
                              </Bounce>
                              <Bounce bottom>
                                <div className="tech-icons">
                                  <img src={Kotlin} alt="" />
                                  <h4>Kotlin</h4>
                                </div>
                              </Bounce>
                              <Bounce bottom>
                                <div className="tech-icons">
                                  <img src={Swift} alt="" />
                                  <h4>Swift</h4>
                                </div>
                              </Bounce>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section> */}
        <HomeTechnologies />
        <LinkedInTracking  Title={'Home'}/>
      </div>
      <Parallax translateY={[-25, 40]}>
        <Footer />
      </Parallax>
    </>
  );
}

export default Home;
