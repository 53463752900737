import React, {useState} from "react";
import { useDropzone } from "react-dropzone";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import ImageIcon from "@mui/icons-material/Image";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";

function ImagePreview({ file }) {
    const previewUrl = URL.createObjectURL(file);
    return <img src={previewUrl} alt={file.name}  style={{ width: 50, height: 50, objectFit: "cover" }} />;
}

function PdfPreview() {
    return <PictureAsPdfIcon sx={{ fontSize: 50 }} />;
}


function Dropzone({ open, setFile }) {
    const { getRootProps, getInputProps, acceptedFiles } =
        useDropzone(
            {
                multiple: false,
                accept: ".pdf, .jpg. jpeg",
                onDrop: (acceptedFiles) => {
                    setFile((prevFiles) =>
                        acceptedFiles.reduce(
                            (acc, file) => ({
                                ...acc,
                                [file.name]: {
                                    file,
                                    fileType: ""
                                }
                            }),
                            prevFiles
                        )
                    );
                }},
        );

    // const files = acceptedFiles.map((file) => (
    //     <li key={file.path}>
    //         {file.path}
    //         - {file.size} bytes
    //     </li>
    // ));
    
    const files = acceptedFiles.map((file) =>(
        <div key={file.path} className="">
            <div class="row no-gutters">
                <div class="col-md-4 pr-0">
                    {file.type.startsWith("image/") ? (
                        <div className="card-header text-center">
                            <ImagePreview file={file} key={file.path} />
                        </div>
                    ) : (
                        <PdfPreview />
                    )}
                </div>
                <div class="col-md-8">
                    <div class="card-body">
                        <span class="card-text small">{file.path}</span> 
                        <br />
                        <span class="card-text"><small class="text-muted">{file.size} bytes</small></span>
                    </div>
                </div>
            </div>
        </div>
    ));

    return (
        <div {...getRootProps({ className: "dropZone" })}>
            <input name='file' className="input-zone" {...getInputProps()} />
            <CloudUploadIcon sx={{fontSize: 100}}/>
            <p className="dropZone-text">
                Drop file here or click to upload
            </p>
            <em>(Only *.jpeg and *.png images and *.pdf document will be accepted)</em>
            {/*<div className="text-center">
                <p className="dropzone-content">
                    Drag’n’drop some files here, or click to select files
                </p>
            </div>*/}
            {/* <aside>
                <ul>{files}</ul>
            </aside> */}
            <div style={{ display: "flex", flexWrap: "wrap" }}>
                {files}
            </div>
        </div>
    );
}
export default Dropzone;
